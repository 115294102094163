
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.clip {
  --clip: 99%;

  clip-path: inset(-1% var(--clip) -1% -1%);
}

::v-deep {
  img {
    width: 100%;
    transform: scale(var(--scale)) translateY(var(--translate));
  }
}
