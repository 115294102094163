
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.strech {
  transform: scaleY(1.1);
  transition: transform 1s $ease-out-quad;
  transform-origin: 0 0;

  @media (prefers-reduced-motion) {
    transform: scaleY(1);
  }
}
