
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























.solution-title {
  --progress: 1;

  display: inline-flex;
  align-items: center;

  @include mq($from: s) {
    padding-right: 4rem;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      opacity: 0.2;
      transform: scaleX(var(--progress));
      transform-origin: 100% 0%;
    }
  }
}

.solution-title__index {
  @include liquid(margin-right, 10px, 40px);
  @include liquid(font-size, 10px, 13px);

  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.solution-title__icon-mask {
  @include liquid(margin-right, 10px, 20px);

  overflow: hidden;
  display: grid;
  place-items: center;
}

.solution-title__icon {
  @include liquid(width, 20px, 37px);
  @include liquid(height, 20px, 37px);

  flex-shrink: 0;
  fill: $c-main;
}

.solution-title__title {
  @include liquid(font-size, 20px, 30px);

  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
}

::v-deep {
  .solution-title__title {
    .v-h {
      // Otherwise accents don't show up
      line-height: 1.5;
    }
  }
}
